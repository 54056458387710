import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import adobaroServices, { ICheckCreatorBaseInfoParams } from '@/services/adobaroServices';
import {
  AddCreatorAccountParams,
  CreatorType,
  CreatorPassportType,
  CreatorForeignPlatform,
  CreatorContractType,
  CreatorPlatformType,
  CreatorBusinessType,
  CategoriesType,
  UserParamsType,
  CreatorCourierType,
} from './types';

export default class AdobaroStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'AdobaroStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  creatorTabKey: string = '';

  setCreatorTabKey(val: string) {
    this.creatorTabKey = val;
  }

  // 添加新的会员账号
  async addCreatorAccount(params: AddCreatorAccountParams) {
    const res = await adobaroServices?.addCreatorAccount(params);
    return Promise.resolve(res);
  }

  creatorList: CreatorType[] = [];
  async getCreatorList(params?: UserParamsType) {
    const res = await adobaroServices.creatorList(params);
    let userList: CreatorType[] = [];
    res?.list.forEach((item) => {
      userList.push(item.user);
    });
    this.saveCreatorList(userList);
    return Promise.resolve(res);
  }

  saveCreatorList = (list: CreatorType[]) => {
    this.creatorList = list;
  };

  creator: CreatorType = {
    avatar: '',
    businesses: [],
    contracts: [],
    countryCode: '',
    email: '',
    foreign_platform: undefined,
    gender: 0,
    gid: '',
    intro: '',
    level: '',
    nickname: '',
    password: '',
    passport: undefined,
    platforms: [],
    phone: '',
    role: 'NORMAL',
    uid: 0,
  };
  async setCreator(creator: CreatorType) {
    this.creator = creator;
  }

  clearCreator() {
    this.creator = {
      avatar: '',
      businesses: [],
      contracts: [],
      countryCode: '',
      email: '',
      foreign_platform: undefined,
      gender: 0,
      gid: '',
      intro: '',
      level: '',
      nickname: '',
      password: '',
      passport: undefined,
      platforms: [],
      phone: '',
      role: 'NORMAL',
      uid: 0,
    };
  }

  //获取达人所属MCN信息
  async getMCNInfo(uid?: number) {
    const res = await adobaroServices?.getMCNInfo(uid);
    return Promise.resolve(res.invitation);
  }

  //获取达人Paypal信息
  async getPaypalInfo(uid?: number) {
    const res = await adobaroServices?.getPaypalInfo(uid);
    return Promise.resolve(res.paypal);
  }

  async getCreatorBaseInfo(uid?: number) {
    const res = adobaroServices?.getCreatorBaseInfo(uid);
    return Promise.resolve(res);
  }

  //修改达人基本信息
  async updateCreatorBaseInfo(params?: CreatorType) {
    const res = await adobaroServices?.updateCreatorBaseInfo(params);
    return Promise.resolve(res);
  }

  //获取达人护照信息
  async getCreatorPassportInfo(uid?: number) {
    const res = await adobaroServices?.getCreatorPassportInfo(uid);
    this.savePassportInfo(res);
    return Promise.resolve(res);
  }

  savePassportInfo(info: CreatorPassportType) {
    this.creator.passport = info;
  }

  async changePassportInfo(params: CreatorPassportType) {
    const res = await adobaroServices?.changePassportInfo(params);
    return Promise.resolve(res);
  }

  //获取达人海外平台信息
  async getForeignPlatform(uid?: number) {
    const res = await adobaroServices?.getForeignPlatform(uid);
    this.saveForeignPlatformInfo(res.foreignPlatform);
    return Promise.resolve(res.foreignPlatform);
  }

  saveForeignPlatformInfo(info: CreatorForeignPlatform) {
    this.creator.foreign_platform = info;
  }

  //获取达人海外平台信息
  async changeForeignPlatform(params?: CreatorForeignPlatform) {
    const res = await adobaroServices?.changeForeignPlatform(params);
    return Promise.resolve(res);
  }

  async getContractList(id: number) {
    const res = await adobaroServices.getContractList(id);
    const data = res.list || [];
    this.saveContractList(data);
    return Promise.resolve(data);
  }

  saveContractList(list: CreatorContractType[]) {
    this.creator.contracts = list;
  }

  async addContractInfo(params: CreatorContractType) {
    const res = await adobaroServices.addContractInfo(params);
    return Promise.resolve(res);
  }

  async updateContractInfo(params: CreatorContractType) {
    const res = await adobaroServices.updateContractInfo(params);
    return Promise.resolve(res);
  }

  //获取达人运营平台列表
  async getOperationPlatformList(uid?: number) {
    const res = await adobaroServices?.getOperationPlatformList(uid);
    this.savePlatformList(res.list);
    return Promise.resolve(res.list);
  }

  savePlatformList(list: CreatorPlatformType[]) {
    this.creator.platforms = list;
  }

  // 新增和修改运营平台
  async updateOperationPlatform(params: CreatorPlatformType) {
    const res = await adobaroServices.updateOperationPlatform(params);
    return Promise.resolve(res);
  }

  // 获取商务信息
  async getBusinessInfo(uid: number) {
    const res = await adobaroServices.getBusinessInfo(uid);
    const data = res.list || [];
    this.saveBusinessList(data);
    return Promise.resolve(data);
  }

  saveBusinessList(list: CreatorBusinessType[]) {
    this.creator.businesses = list;
  }

  async addBusinessInfo(params: CreatorBusinessType) {
    const res = await adobaroServices.addBusinessInfo(params);
    return Promise.resolve(res.data);
  }

  async updateBusinessInfo(params: CreatorBusinessType) {
    const res = await adobaroServices.updateBusinessInfo(params);
    return Promise.resolve(res.data);
  }

  async deleteBusinessInfo(id: number) {
    const res = await adobaroServices.deleteBusinessInfo(id);
    return Promise.resolve(res.data);
  }

  //是否展示运营日志
  showLog: boolean = false;
  setShowLog(showLog: boolean) {
    this.showLog = showLog;
  }

  // 获取creator的所有账户分类
  categoriesList: CategoriesType[] = [];
  async getCategories(page = 1, size = 999) {
    if (this.categoriesList?.length > 0) {
      return Promise.resolve(this.categoriesList);
    }
    const params = {
      size,
      page,
    };
    const res = await adobaroServices.getCategories(params);
    this.categoriesList = res.data;
    // this.getDisplayCateList(res.data);
    return Promise.resolve(res.data);
  }

  displayCateName(id?: number, language: 'cn' | 'en' | 'kr' = 'cn') {
    if (!id) {
      return '-';
    }
    let cate = '';
    let cateParentId = id;
    do {
      // eslint-disable-next-line no-loop-func
      const cateItem = this.categoriesList?.find((item) => item.category_id === cateParentId);
      if (cateItem) {
        cate = cateItem[`${language}_name`] + `${cate ? `/${cate}` : cate}`;
        cateParentId = cateItem.parent_id;
      } else {
        cateParentId = 0;
      }
    } while (cateParentId !== 0);
    return cate || '-';
  }

  // 获取运营日志
  async getUserOperateLogs(size = 10, page = 1) {
    const params = {
      size,
      page,
      uid: this.creator.uid,
    };
    const res = await adobaroServices.getUserOperateLogs(params);
    return Promise.resolve(res.data);
  }

  // 获取达人各平台的数据
  async getCreatorPlatformsData(uid: number) {
    const res = await adobaroServices?.getCreatorPlatformsData(uid);
    return Promise.resolve(res);
  }

  async checkCreatorBaseInfo(params: ICheckCreatorBaseInfoParams) {
    const res = await adobaroServices?.checkCreatorBaseInfo(params);
    return Promise.resolve(res.data);
  }

  creatorCourierInfo: CreatorCourierType = {
    uid: 0,
    email: '',
    lang: '',
    isNotice: false,
  };

  setCreatorCourierInfo(info: CreatorCourierType) {
    this.creatorCourierInfo = info;
  }

  // 获取达人邮箱通知状态
  async getCreatorCourierInfo(uid: number) {
    const res = await adobaroServices?.getCreatorCourierInfo(uid);
    this.setCreatorCourierInfo(res);
    return Promise.resolve(res);
  }
}
