const jp = {
  "global": {
    "language": "日本語",
    "not_found_title": "探しているページはありません",
    "not_found_tips": "おそらく間違ったパスが検索されました。",
    "login_greeting_morning": "おはよう!",
    "login_greeting_afternoon": "こんにちは!",
    "login_greeting_evening": "こんばんは!",
    "login_password_title": "パスワードログイン",
    "login_password_holder": "パスワードを入力してください",
    "login_password_error": "パスワードの数字4〜20桁",
    "login_email_holder": "メールアドレスを入力してください",
    "login_email_error": "メールアドレスが正しいか確認してください",
    "login_code_title": "確認コードログイン",
    "login_qrcode_title": "コードをスキャンしてログインする",
    "login_qrcode_tips": "请使用{0}扫码登录",
    "login_qrcode_feishu": "飞书App",
    "login_qrcode_new_tips": "使用飞书扫一扫登录 adoba studio",
    "login_button": "ログイン",
    "login_forgot": "パスワードをお忘れですか",
    "email_holder": "请输入邮箱",
    "email_error": "邮箱格式错误！",
    "password_error": "8-20字以上的大小写英文，数字，特殊文字（都要包括）",
    "login_logout": "ログアウト",
    "modal_cancel": "キャンセル",
    "modal_delete": "削除",
    "modal_create": "創建",
    "modal_save": "保存",
    "modal_confirm": "本気ですか？",
    "btn_add": "添加",
    "btn_change": "修正",
    "btn_delete": "削除",
    "btn_save": "保存",
    "btn_confirm": "確定",
    "btn_check": "查看",
    "btn_edit": "编辑",
    "message_success_create": "成功する！",
    "message_error_create": "創建失敗！",
    "message_success_update": "修正成功！",
    "message_error_update": "修正失敗！",
    "message_success_delete": "削除成功！",
    "message_error_delete": "削除失敗！",
    "message_success_upload": "アップロード成功！",
    "message_error_upload": "アップロード失敗！",
    "message_no_token": "登録タイムアウト、再登録してください",
    "message_error_login_code": "登录错误，请联系开发部门！",
    "message_no_permission": "権限がない",
    "upload_file_format": "文件格式仅支持：",
    "upload_file_size": "文件必须小于",
    "form_holder_require": "この項目を入力!",
    "unselected_creator_tips": "データなし、左側の作成者を選択してください",
    "platform_hao_kan": "好看视频",
    "platform_tencent": "腾讯视频",
    "platform_iqiyi": "爱奇艺",
    "platform_bilibili": "哔哩哔哩",
    "platform_xi_gua": "西瓜视频",
    "platform_wei_bo": "微博",
    "platform_youtube": "Youtube",
    "platform_dou_yin": "抖音",
    "platform_red_book": "小红书",
    "platform_kuai_shou": "快手",
    "platform_cloud_music": "网易云音乐",
    "platform_wechat_video": "微信视频号",
    "platform_mei_pai": "美拍",
    "platform_da_yu": "大鱼号",
    "platform_dian_tao": "点淘",
    "platform_qq_world": "QQ小世界",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "language_en": "英語です",
    "language_zh": "中文",
    "language_ko": "ハングルです"
  },
  "dashboard": {
    "work_bench": "作業台",
    "adobaro_manage": "アカウント管理",
    "account_manage": "V1 アカウント",
    "road_manage": "ユーザー管理",
    "data_analysis": "データ解析",
    "income_manage": "収益管理",
    "order_manage": "ビジネスオーダーの管理",
    "personal_center": "個人センター",
    "feedback": "意見のフィードバック",
    "experimental_center": "実験センター",
    "close_sidebar": "サイドバーを閉",
    "translation_manage": "AI じまく",
    "notice_manage": "通知管理"
  },
  "account": {
    "search_placeholder": "adoba id/アカウント名/携帯電話番号を検索",
    "export_operation_info": "ダウンロード",
    "add_new_account": "新しいアカウントを追加",
    "creator_list_all": "全体",
    "creator_list_fav": "関心",
    "creator_list_manage": "管理",
    "creator_list_sort_fans": "サブスクライバ順",
    "creator_list_sort_views": "数順",
    "creator_list_sort_uprate": "上昇率順",
    "creator_list_v2_add_btn": "添加账号",
    "creator_list_v2_add_modal_title": "添加新的会员账号",
    "creator_list_v2_email_exists": "该邮箱已存在，请修改后重新输入",
    "header_no": "番号",
    "header_country": "国家",
    "header_contract_end": "契約終了",
    "header_cate": "分類",
    "header_type": "タイプ",
    "header_expired": "期限切れ",
    "header_days": "日",
    "header_operate_status": "運営現状",
    "header_personal_Info": "個人情報",
    "card_operate_platform": "運営プラットフォーム",
    "card_operate_log": "運営日誌",
    "card_operate_info": "運営情報",
    "card_business_info": "ビジネス情報",
    "card_base_info": "達人の基本情報",
    "card_foreign_info": "海外プラットフォーム情報",
    "card_passport_info": "护照信息(L1)",
    "card_contract_info": "契約情報(L2)",
    "card_bank_info": "銀行情報",
    "platform_is_bind": "結びつけられている",
    "platform_un_bind": "結び付けていない",
    "platform_fans": "フォロワー数",
    "platform_add": "運営プラットフォームを追加する",
    "platform_name": "プラットホーム",
    "platform_account_name": "アカウント",
    "platform_url": "URL",
    "platform_uid": "UID",
    "platform_start_date": "先発時間",
    "platform_bind_phone": "電話",
    "platform_bind_email": "Email",
    "platform_mcn_bind": "MCN",
    "platform_mcn_validity": "MCN 有効期限",
    "platform_withdrawal": "キャッシュアウト状況",
    "platform_un_authorize": "未授権",
    "platform_is_authorize": "授権済み",
    "platform_main_page": "ホームページ",
    "platform_password": "パスワード",
    "platform_pid": "PID",
    "platform_tao_bao": "淘宝リーグ",
    "platform_JD": "JDリーグ",
    "platform_pin_duo_duo": "ピンドゥードゥー",
    "platform_mo_gu": "マッシュルーム通り",
    "platform_not_begun": "始まっていない",
    "platform_not_started": "先発しない",
    "platform_change_title": "情報を修正",
    "platform_holder_name": "選択プラットホーム",
    "platform_holder_account_name": "入力アカウント",
    "platform_holder_url": "入力url",
    "platform_holder_uid": "入力UID",
    "platform_holder_start_date": "時間を選ぶ",
    "platform_holder_bind_phone": "入力電話",
    "platform_holder_bind_email": "入力Email",
    "platform_holder_mcn_bind": "選択mcn",
    "platform_holder_withdrawal": "選択キャッシュアウト状況",
    "platform_holder_join_page": "ページを開く",
    "platform_holder_password": "入力パスワード",
    "platform_holder_pid": "PIDを入力してください",
    "platform_modal_confirm": "当該運営プラットフォームの削除は確定ですか?",
    "platform_check_code": "国コードを選んでください!",
    "platform_check_email": "メールの書式が間違っています!",
    "platform_check_phone": "不正な電話番号！",
    "platform_check_password": "パスワードの数字6〜16桁",
    "operate_status_operating": "運営中",
    "operate_status_cancelled": "解約",
    "operate_status_unsigned": "未契約",
    "operate_status_unprocessed": "未運営",
    "contract_type_personal": "個人",
    "contract_type_enterprise": "企業",
    "contract_type_mcn": "MCN",
    "operate_head": "責任者",
    "operate_docking": "ドッキングマン",
    "operate_start_date": "運営開始",
    "operate_start_day": "日",
    "operate_not_begun": "始まっていない",
    "operate_frequency": "更新周波数",
    "operate_frequency_week": "周",
    "operate_frequency_month": "月",
    "operate_frequency_times": "回",
    "operate_note": "更新备考",
    "operate_delay": "延期",
    "operate_delay_can": "できる",
    "operate_delay_not": "できません",
    "operate_subtitles": "字幕",
    "operate_order": "受注",
    "operate_order_can": "できる",
    "operate_order_not": "できません",
    "operate_cooperate": "協力レベル",
    "operate_cover": "ジャケット",
    "operate_cover_creator": "達人が提供する",
    "operate_cover_self": "自制",
    "operate_source": "ビデオソース",
    "operate_source_creator": "達人が提供する",
    "operate_source_self": "自分でダウンロードする",
    "operate_major": "注意事項",
    "operate_change_title": "情報を修正",
    "operate_add_title": "情報を追加",
    "operate_creator_self": "CR自分",
    "operate_repeat_error": "担当者と同じではいけません!",
    "operate_holder_head": "選択責任者",
    "operate_holder_docking": "選択ドッキングマン",
    "operate_holder_start_date": "選択时间",
    "operate_holder_frequency": "入力回数",
    "operate_holder_number": "数字を入力してください",
    "operate_holder_note": "入力更新备考",
    "operate_holder_delay": "選択延期状況",
    "operate_holder_subtitles": "入力字幕状況",
    "operate_holder_order": "選択受注状況",
    "operate_holder_cover": "選択ジャケット情况",
    "operate_holder_source": "選択動画源",
    "operate_holder_major": "入力注意事項",
    "operate_check_frequency": "周波数タイプを選んでください!",
    "person_base_check_frequency": "周波数を入力してください!",
    "person_base_check_times": "回数を入力してください！",
    "business_price": "参考価格",
    "business_add": "添加",
    "business_add_title": "添加ビジネス情報",
    "business_change_title": "修正ビジネス情報",
    "business_name": "プラットフォーム",
    "business_implant": "ビデオを埋め込む",
    "business_custom": "カスタマイズ動画",
    "business_dynamic": "動的価格",
    "business_forward": "転送価格",
    "business_holder_name": "入力プラットフォーム名",
    "business_holder_implant": "入力ビデオを埋め込む価格",
    "business_holder_custom": "入力カスタマイズ動画価格",
    "business_holder_dynamic": "入力動的価格",
    "business_holder_forward": "入力転送価格",
    "log_update_content": "達人'{0}'の{1}{2},'{3}'は'{4}'から'{5}'に変更",
    "log_update_pid_up": "達人'{0}'の{1}{2}の中の'{3}','{4}'は'{5}'から'{6}'に更新する",
    "log_update_pid_add_del": "達人'{0}'の{1}'{2}',{3}'{4}'の{5}",
    "log_create_delete_content": "達人'{0}'は{1}の中の{2}",
    "log_create_content": "達人{0}の{1}",
    "person_base_info": "基本情報",
    "person_base_avatar": "顔",
    "person_base_nickname": "ニックネーム",
    "person_base_adoba_id": "adoba ID",
    "person_base_nation": "国籍",
    "person_base_phone": "電話",
    "person_base_email": "E-mail",
    "person_notice": "邮箱通知",
    "person_notice_received": "接收",
    "person_notice_not": "不接收",
    "person_base_cate": "分類",
    "person_base_intro": "简介",
    "person_base_add": "新たな達人を加える",
    "person_base_add_tip": "*管理者権限を持つユーザーだけが追加/修正できる",
    "person_message_success": "成功を加える！",
    "person_message_error": "失敗を追加する！",
    "person_message_error_details": "ニックネームやadobaIDが存在する",
    "person_base_modal_title": "達人の基本情報を見直す",
    "person_base_holder_avatar": "掲載顔",
    "person_base_holder_nickname": "ニックネームは繰り返してはいけない！",
    "person_base_holder_id": "adoba IDは重複できない",
    "person_base_holder_nation": "国籍を選んでください",
    "person_base_holder_phone": "携帯電話の番号は重複しない",
    "person_base_holder_email": "メールアドレスを入力してください",
    "person_base_holder_cate": "分類を選んでください",
    "person_base_holder_intro": "達人プロフィールを入力してください",
    "person_base_check_code": "国コードを選んでください!",
    "person_base_check_id": "adoba IDフォーマットエラー",
    "person_base_check_phone": "不正な電話番号！",
    "person_base_check_email": "メールの書式が間違っています!",
    "person_base_exist_nickname": "ニックネームはすでに存在する！",
    "person_base_exist_id": "adoba IDすでに存在する",
    "person_base_exist_phone": "携帯番号はすでに存在している!",
    "person_overseas_platform": "ラットフォーム名",
    "person_overseas_name": "口座名",
    "person_overseas_url": "ホームページへのリンク",
    "person_overseas_uid": "UID",
    "person_overseas_modal_title": "海外のプラットフォーム情報を修正",
    "person_overseas_holder_platform": "プラットフォームを選択してください",
    "person_overseas_holder_name": "海外口座名を入力してください",
    "person_overseas_holder_url": "ホームページのリンクを入力してください",
    "person_overseas_holder_uid": "UIDを入力してください",
    "person_overseas_check_uid": "数字を入力してください",
    "person_privacy_info": "プライバシー情報",
    "person_passport_no": "パスポート番号",
    "person_passport_name": "パスポート名",
    "person_passport_expire": "パスポートの有効日",
    "person_passport_birth": "诞生日",
    "person_passport_address": "アドレ",
    "person_passport_modal_title": "パスポート情報の変更",
    "person_passport_holder_no": "パスポート番号を入力してください",
    "person_passport_holder_name": "パスポート名を入力してください",
    "person_passport_holder_expire": "パスポートの有効日を選んでください",
    "person_passport_holder_birth": "誕生日を選んでください",
    "person_passport_holder_address": "住所を入力してください",
    "person_contract_no": "契約番号",
    "person_contract_cate": "分類",
    "person_contract_date": "契約の起終期日",
    "person_contract_start_date": "発効日",
    "person_contract_expire_date": "期日",
    "person_contract_operate": "単独運営か",
    "person_contract_operate_true": "はい",
    "person_contract_operate_false": "いいえ",
    "person_contract_operate_ratio": "運用収益の達人比率",
    "person_contract_ad": "独占ビジネスかどうか",
    "person_contract_ad_ratio": "ビジネス収益の達人の割合",
    "person_contract_remark": "契約に関する備考",
    "person_contract_modal_title": "契約情報を修正する",
    "person_contract_remove": "解約状態",
    "person_contract_remove_date_true": "はい",
    "person_contract_not_operating": "未運営",
    "person_contract_not_operating_true": "はい",
    "person_contract_remove_date": "解約日",
    "person_contract_not_operating_date": "運営停止日",
    "person_contract_true": "はい",
    "person_contract_false": "いいえ",
    "person_contract_holder_no": "契約番号を入力してください",
    "person_contract_holder_cate": "分類を選んでください",
    "person_contract_holder_operate": "単独で運営するかどうかを选んでください",
    "person_contract_holder_operate_ratio": "達人の収益率、0~100の数字を入力せよ",
    "person_contract_holder_ad": "独占ビジネスかどうかを選んでください",
    "person_contract_holder_ad_ratio": "達人の収益率、0~100の数字を入力せよ",
    "person_contract_holder_remove_date": "解約日を選んでください",
    "person_contract_holder_not_operating_date": "運営停止日を選んでください",
    "person_contract_holder_remark": "契約関係備考を入力してください",
    "person_contract_check_operate": "間違った形式！",
    "person_contract_check_ad_ratio": "間違った形式！",
    "person_contract_info_v2": "契約情報",
    "person_contract_upload_file": "文件上传",
    "person_contract_check_file": "查看文件",
    "person_contract_status": "合同状态",
    "person_contract_status_holder": "请选择合同状态",
    "person_contract_status_cooperated": "合作中",
    "person_contract_status_terminated": "已解约",
    "contract_type_authorize": "授权书",
    "contract_type_other": "其他",
    "person_bank_nation": "銀行の所在地",
    "person_bank_name": "銀行名",
    "person_bank_account": "銀行口座",
    "person_bank_address": "銀行の英語の住所",
    "person_bank_swift_code": "銀行Swift Code",
    "person_bank_user_name": "名前",
    "person_bank_id_card": "決済者身分証",
    "person_bank_modal_title": "銀行情報を修正する",
    "person_bank_holder_nation": "銀行の所在地を選んでください",
    "person_bank_holder_name": "銀行名を入力してください",
    "person_bank_holder_account": "銀行口座を入力してください",
    "person_bank_holder_address": "銀行の英語の住所を入力してください",
    "person_bank_holder_swift_code": "銀行Swift Codeを入力してください",
    "person_bank_holder_user_name": "名前を入力してください",
    "person_bank_holder_id_card": "決済者身分証を入力してください",
    "passport_dashboard_title": "签名的护照扫描件",
    "passport_handhold_title": "已签名的护照和一张自拍照",
    "person_level": "会员等级",
    "account_type": "账号分类",
    "bind_mcn": "所属MCN"
  },
  "road": {
    "search_placeholder": "uid/アカウント名/emailを検索",
    "creator_list_applied": "申請済み",
    "creator_list_open_manage": "開通申請管理",
    "creator_list_all": "すべて",
    "creator_status_cancelled": "已注销",
    "check_identity": "資料を調べる",
    "header_notice": "メールによる通知",
    "header_notice_open": "開く",
    "header_notice_close": "閉鎖",
    "identity_info": "資料情報",
    "passport_info": "パスポート情報",
    "overseas_info": "海外プラットフォーム情報",
    "account_info": "運営情報",
    "info_confirm": "情報の確認",
    "account_avatar": "アバター",
    "account_name": "アカウント名",
    "account_name_holder": "アカウント名を入力してください",
    "account_introduction": "简介",
    "account_introduction_holder": "プラットフォームの概要を入力してください",
    "account_type": "分類",
    "account_type_holder": "アカウントの分類を選択してください",
    "account_phone": "電話",
    "account_phone_holder": "電話を入力してください",
    "account_email": "メール",
    "account_email_holder": "メールアドレスを入力してください",
    "platform_case": "プラットフォームの事例",
    "overseas_platform": "海外プラットフォーム",
    "overseas_platform_holder": "海外のプラットフォームを選ぶ",
    "overseas_link": "海外プラットフォームリンク",
    "overseas_link_holder": "海外プラットフォームのリンクを入力してください",
    "overseas_link_error": "海外プラットフォームへのリンクは不正確、httpまたはhttpsで始まる",
    "overseas_link_method": "海外プラットフォームへのアクセス方法を知る",
    "overseas_link_method_tips": "海外プラットフォームのホームページから、現在のページのリンクをコピーします。",
    "overseas_method": "詳しい入手方法",
    "overseas_nickname": "海外プラットフォームのニックネーム",
    "overseas_nickname_holder": "海外プラットフォーマーのニックネームを入力してください",
    "overseas_upload_proof": "証明書類をアップロードする",
    "overseas_main_page": "ホームページのスクリーンショット",
    "overseas_upload_main_page": "携帯電話またはパソコンのスクリーンショットをアップロードしてください",
    "overseas_main_page_tips": "以下の例でスクリーンショットしてください",
    "overseas_backstage": "舞台裏で本人とツーショット",
    "overseas_upload_backstage": "本人との写真をアップロードしてください",
    "overseas_backstage_tips": "以下の例で写真を撮ってください",
    "personal_passport_name": "パスポートの英語名",
    "personal_passport_name_holder": "パスポートの英語名を入力してください",
    "personal_passport_number": "パスポート番号",
    "personal_passport_number_holder": "パスポート番号を入力してください",
    "personal_passport_date": "有効期限",
    "personal_passport_date_holder": "パスポートの有効期限を選んでください",
    "personal_passport_date_error": "パスポートの有効期間は6か月以下ではいけない",
    "personal_upload_proof": "証明書類をアップロードする",
    "personal_upload_passport": "パスポートのスキャン",
    "personal_upload_passport_holder": "パスポートのスキャンをアップロードしてください",
    "personal_upload_passport_tips": "以下の例で写真を撮ってください",
    "personal_method": "詳しい入手方法",
    "personal_passport_hold": "パスポートの写真を手に",
    "personal_passport_hold_holder": "パスポートを持った写真をアップロードしてください",
    "personal_passport_hold_tips": "以下の例で写真を撮ってください",
    "personal_passport_success": "パスポート情報の更新成功",
    "personal_passport_fail": "パスポート情報の更新に失敗",
    "personal_oversea_success": "海外プラットフォーム情報の更新に成功",
    "personal_oversea_fail": "海外プラットフォームの情報更新に失敗",
    "confirm_info": "情報を確認する",
    "submit_material_success": "プラットフォーム資料の提出成功",
    "submit_material_fail": "プラットフォームの資料提出に失敗",
    "header_title": "開通申請管理",
    "header_no": "UID",
    "header_cate": "分類",
    "table_platform": "プラットフォーム",
    "table_apply_date": "申請期日",
    "table_off_site_problem": "駅外問題",
    "table_data_problem": "資料の問題",
    "table_confirm_problem": "認証問題",
    "table_finished": "済み",
    "table_finished_date": "期日を終える",
    "table_account_name": "アカウント名",
    "table_phone": "電話",
    "table_email": "メール",
    "table_password": "パスワード",
    "table_action": "操作",
    "progress_not_start": "始まっていない",
    "progress_ongoing": "開通中",
    "progress_completed": "完成した",
    "progress_open_completed": "開通が完了する",
    "progress_open_failed": "開通に失敗する",
    "progress_modal_title": "プラットフォーム開通の進捗",
    "table_apply_date_remaining": "D-{0}day",
    "table_apply_date_expire": "D+{0}day",
    "table_modal_steps_tips": "需要开启数据追踪功能才可以操作完毕。",
    "table_modal_steps_to_open": "去开启",
    "table_modal_password_holder": "パスワードを入力してください",
    "table_modal_note": "备注",
    "table_modal_note_holder": "请输入备注",
    "modal_message_err_status": "ステータス更新失敗!",
    "modal_message_err_password": "パスワードの更新に失敗!",
    "modal_message_err_tracking": "请先开启数据追踪！",
    "platform_account_name_error": "中国語と英語と数字しか入力できません",
    "platform_is_stopped": "データ追跡",
    "platform_is_stopped_confirm": "重要な作業は再確認してください",
    "platform_is_stopped_must_read": "必读",
    "modal_footer_previous": "進度",
    "modal_footer_previous_btn": "一歩上",
    "all_platform_modal_tips_attention": "注意",
    "all_platform_modal_tips_link": "主页地址链接需正确填写。",
    "all_platform_modal_tips_mcn": "MCN必须已绑定。",
    "all_platform_modal_tips_nonsupport": "小红书平台暂不支持数据追踪。",
    "all_platform_modal_tips_times": "每次操作间隔6小时。",
    "all_platform_modal_tips_ failure": "等待5-10分钟可知道追踪结果，追踪失败时会自动关闭，需要找开发部询问处理。",
    "data_tracking_confirm_open_title": "确定开启数据追踪吗？",
    "data_tracking_confirm_close_title": "确定关闭数据追踪吗？",
    "data_tracking_countdown_label": "距下次可操作",
    "data_list_count": "共{}条数据"
  },
  "adobaro": {
    "income_manage": "收益管理",
    "total_income_amount": "累计总收入",
    "total_withdrawal_amount": "累计总结算",
    "balance_amount": "钱包余额",
    "settlement_title": "结算信息",
    "monetary_unit_label": "单位",
    "monetary_unit_cny": "元",
    "monetary_unit_krw": "韩元",
    "monetary_unit_usd": "美元",
    "settlement_date": "提现日期",
    "settlement_total": "收益合计(含税)",
    "settlement_tax": "扣税",
    "settlement_service_charge": "手续费",
    "settlement_amount": "打款金额",
    "settlement_status": "打款状态",
    "settlement_action": "操作",
    "settlement_status_ongoing": "处理中",
    "settlement_status_success": "提现成功",
    "settlement_status_failure": "提现失败",
    "settlement_status_failure_error": "您的Paypal账户信息错误，未能付款成功。请检查后重新提交。",
    "settlement_status_failure_other": "其他原因导致付款失败。",
    "settlement_to_account_date": "预计{0}到账",
    "settlement_view_details": "查看详细",
    "account_paypal": "Paypal",
    "settlement_detail_period": "结算周期",
    "settlement_detail": "提现明细",
    "settlement_detail_amount": "平台收益",
    "settlement_detail_tax": "扣税",
    "settlement_detail_service_fee": "手续费",
    "settlement_detail_settlement_amount": "结算金额",
    "settlement_detail_bank_info": "银行信息",
    "settlement_detail_bank_btn": "填写银行信息",
    "settlement_detail_to_bank": "填写银行信息后，才能申请提现",
    "settlement_detail_tips_time": "· 申请后30个工作日内到账户",
    "settlement_detail_tips_level": "· 手续费按照会员等级变动",
    "settlement_detail_tips_rate": "· 收益结算时货币收益根据汇率而变化",
    "settlement_detail_tips_bank": "· 账户信息不正确的话，申请提现会失败",
    "settlement_detail_tips_tax": "· 按照税法，需要扣所得税3.3%",
    "settlement_detail_apply_btn": "申请提现",
    "settlement_detail_money_rate": "打款汇率",
    "settlement_detail_money_amount": "打款金额",
    "settlement_detail_tips_krw": "· 打款韩币已经扣个人所得税3.3%",
    "income_title": "收益情况",
    "platform_total": "总收入(元)",
    "platform_all": "全部平台",
    "platform_income_detail": "平台收益详细",
    "platform_table_name": "平台",
    "platform_table_income": "平台收益(元)",
    "platform_table_fee": "手续费(元)",
    "platform_table_tax": "扣税(元)",
    "platform_table_balance": "可提现金额(元)",
    "platform_table_daily_income": "每日收益",
    "platform_drawer_title": "{0}{1}收入详情",
    "platform_drawer_tips_wait": "各平台数据收集时间不同，请耐心等待",
    "platform_drawer_tips_unit": "收益金额是人民币",
    "platform_drawer_tips_account": "日期收益以MCN账户到账为准",
    "platform_drawer_date": "日期",
    "platform_drawer_plan": "会员",
    "platform_drawer_income": "平台收益(元)",
    "platform_drawer_fee": "手续费(元)",
    "platform_drawer_tax": "扣税(元)",
    "platform_drawer_balance": "结算金额(元)",
    "platform_drawer_total": "总计",
    "platform_drawer_collecting": "数据收集中",
    "platform_drawer_show_more": "展开更多",
    "platform_drawer_show_close": "收起",
    "platform_drawer_platform_all": "平台总收益"
  },
  "income": {
    "header_view_market_btn": "查看数据大盘",
    "header_export_settlement_btn": "导出结算信息",
    "header_tax_manage_btn": "扣税管理",
    "tax_manage_modal_title": "扣税管理",
    "tax_manage_tax_item": "扣税{0}",
    "tax_manage_name": "扣税名称",
    "tax_manage_rate": "扣税比率",
    "tax_manage_text": "扣税说明",
    "tax_manage_name_holder": "请输入扣税名称",
    "tax_manage_rate_holder": "请输入扣税比率",
    "tax_manage_text_holder": "请输入扣税说明",
    "export_settlement_file_name": "结算信息",
    "export_settlement_title_tips": "默认导出全部",
    "export_settlement_date_range": "起止日期",
    "export_settlement_status": "导出类型",
    "export_settlement_date_start_holder": "请选择开始日期",
    "export_settlement_date_end_holder": "请选择结束时间",
    "export_settlement_status_holder": "请选择导出类型",
    "creator_list_tabs_apply": "申请中",
    "creator_list_tabs_applied": "已申请",
    "creator_list_tabs_reviewed": "已审核",
    "creator_list_tabs_transfered": "已转账",
    "creator_list_tabs_rejected": "已驳回",
    "creator_list_tabs_failed": "已失败",
    "creator_list_tabs_all": "全部",
    "market_income_title": "收益大盘",
    "market_income_subtitle": "数据是前天为准",
    "market_income_all_income": "全平台累计收益",
    "market_income_all_opens": "全平台开通数",
    "market_income_all_opens_contrast": "平台开通数(比上个月)",
    "market_income_all_income_contrast": "全平台收益(比上个月)",
    "market_cash_title": "提现大盘",
    "market_cash_apply_money": "已申请金额(含税)",
    "market_cash_unfinished_money": "未提现金额(含税)",
    "market_cash_apply_user": "申请提现用户",
    "income_monetary_unit_label": "单位",
    "income_monetary_unit_cny": "元",
    "income_monetary_unit_krw": "韩币",
    "income_monetary_unit_won": "韩币",
    "income_monetary_unit_usd": "美元",
    "details_header_payee": "收款人",
    "details_table_apply_date": "申请日期",
    "details_table_settlement_period": "结算周期",
    "details_table_apply_amount": "申请金额(含税)",
    "details_table_tax_deduction": "扣税",
    "details_table_service_charge": "服务费",
    "details_table_transfer_status": "打款状态",
    "details_table_action": "操作",
    "details_table_transfer_status_ongoing": "处理中",
    "details_table_transfer_status_success": "打款成功",
    "details_table_transfer_status_failure": "打款失败",
    "details_table_action_to_withdrawal": "提现执行",
    "details_table_action_view_withdrawal": "查看提现",
    "details_bank_info": "银行信息",
    "details_bank_name": "银行名称",
    "details_bank_account_number": "银行账户",
    "details_bank_account_name": "账户名",
    "details_bank_real": "真实姓名",
    "details_bank_identity_number": "身份证号码",
    "details_bank_currency": "结算货币",
    "details_ip_ad_income": "IP广告收益(比上个月)",
    "details_total_amount": "总金额",
    "details_withdrawable_amount": "可提现金额",
    "details_cumulative_withdrawal amount": "累计提现金额",
    "withdrawal_modal_title_details": "提现详情",
    "withdrawal_modal_withdrawal_operation": "提现操作",
    "withdrawal_modal_apply_date": "申请日期",
    "withdrawal_modal_settlement_period": "结算周期",
    "withdrawal_modal_withdrawal_detail": "提现明细",
    "withdrawal_modal_platform_income": "平台收益",
    "withdrawal_modal_ad_income": "广告收益",
    "withdrawal_modal_tax1": "扣税1",
    "withdrawal_modal_tax2": "扣税2",
    "withdrawal_modal_tax3": "扣税3",
    "withdrawal_modal_service_charge": "服务费",
    "withdrawal_modal_after_tax_amount": "税后金额",
    "withdrawal_modal_payment_details": "打款明细",
    "withdrawal_modal_expected_date": "打款预计日期",
    "withdrawal_modal_exchange_rate": "打款汇率",
    "withdrawal_modal_exchange_amount": "汇率金额",
    "withdrawal_modal_personal_tax": "个人所得税3.3%",
    "withdrawal_modal_personal_paypal": "Paypal订单号",
    "withdrawal_modal_payment_amount": "打款金额",
    "withdrawal_modal_payment_status": "打款状态",
    "withdrawal_modal_cause_of_failure": "失败原因",
    "withdrawal_modal_currency": "结算货币",
    "withdrawal_modal_expected_date_holder": "请选择日期",
    "withdrawal_modal_exchange_rate_holder": "请输入打款汇率",
    "withdrawal_modal_exchange_amount_holder": "请输入汇率金额",
    "withdrawal_modal_personal_tax_holder": "请输入个人所得税",
    "withdrawal_modal_payment_amount_holder": "请输入打款金额",
    "withdrawal_modal_cause_of_failure_holder": "请输入失败原因",
    "withdrawal_modal_cause_failure_holder": "请选择失败原因",
    "withdrawal_modal_failure_option_error": "您的Paypal账户信息错误，未能付款成功。请检查后重新提交。",
    "withdrawal_modal_failure_option_other": "其他原因导致付款失败，请通过客服中心进行咨询处理。",
    "withdrawal_modal_currency_holder": "请选择结算货币",
    "withdrawal_modal_personal_paypal_holder": "请输入打款订单号"
  },
  "experimental": {
    "download_software": "下载登录软件",
    "software_terminal": "终端版",
    "software_interface": "界面版",
    "more_exciting": "更多精彩敬请期待~"
  },
  "notice": {
    "notice_send_notice": "構内通知をお送りします",
    "notice_record": "構内案内記録です",
    "notice_search_placeholder": "UIDを検索します",
    "notice_send_target": "送信対象です",
    "notice_send_content_title": "訳文1送信内容です",
    "notice_send_title": "見出しです",
    "notice_send_content": "中身です",
    "notice_send_link": "リンクします",
    "notice_send_title_placeholder": "タイトル入力をお願いします。",
    "notice_send_content_placeholder": "内容の入力をお願いします",
    "notice_send_link_placeholder": "リンク入力をお願いします。",
    "notice_ai_translation_btn": "AI翻訳です",
    "notice_ai_translation_lang": "AI翻訳言語です",
    "notice_send_notification": "通知を送ります。",
    "notice_send_user": "ユーザーを設定します",
    "notice_record_page_title": "構内案内送信記録です",
    "notice_record_search": "検索します",
    "notice_record_show": "表示します",
    "notice_record_tip": "条です",
    "notice_record_all": "共です",
    "notice_record_refresh": "リフレッシュします",
    "notice_record_sender": "送信者です",
    "notice_record_title": "見出しです",
    "notice_record_send_time": "発送時間です",
    "notice_record_details": "詳細です",
    "notice_record_view_details": "詳細を調べます。",
    "notice_details_modal_title": "詳細をお知らせします",
    "notice_receiver_id": "受信者IDです",
    "notice_create_time": "作成時間です",
    "notice_details_content": "内容をお知らせします",
    "notice_details_en": "英語版です",
    "notice_details_zh": "中国語版です",
    "notice_details_ko": "ハングル版です",
    "notice_select_all": "全て",
    "notice_content_too_long": "{0}の翻訳後の通知内容の文字数は1000文字を超えることはできません。",
    "notice_translate_success": "AI翻訳成功",
    "notice_translate_error": "AI翻訳失敗",
    "notice_send_success": "通知送信成功",
    "notice_send_error": "通知送信失敗",
    "notice_select_user": "受信者を選択してください",
    "notice_search_time": "搜索发送时间：",
    "notice_start_time": "开始时间",
    "notice_end_time": "结束时间",
    "notice_search": "搜索{0}",
    "notice_reset": "重置",
    "notice_clear_all": "清除全部",
    "notice_word_limit": "字数不能超过1000字",
    "notice_send_confirm": "您确定要向以下用户发送通知吗？",
    "notice_selected_user": "已选择用户：",
    "notice_all_user": "全部用户",
    "notice_send_ok": "确认",
    "notice_send_cancel": "取消"
  },
  "translation": {
    "srt_translation": "AI じまく",
    "srt_translation_success": "成功的に作成され、ダウンロード中です。ダウンロードフォルダを確認してください。\"",
    "srt_translation_error": "作成に失敗しました。再度ファイルをアップロードしてください。",
    "srt_select_target_lang": "対象言語を選択します。",
    "srt_select_translation_model": "翻訳モデルをお願いします。",
    "srt_select_translation_engine": "翻訳エンジンを選択してください",
    "srt_select_chatgpt_model": "ChatGptモデルを選択してください",
    "srt_upload_file": "SRT/MP3ファイルのアップロードをお願いします",
    "srt_upload_btn": "ファイルアップロード",
    "srt_translate_btn": "AI字幕生成",
    "srt_language_self": "ソースファイル言語",
    "srt_target_lang_description": "アップロードしたファイルは{0}に翻訳される予定です。",
    "srt_subtitle": "SRTまたはMP3ファイルがあれば、AIじまくが可能です。",
    "srt_model_description_tiny": "非常に速いですが,精度はまずまず",
    "srt_model_description_base": "速く,精度が高いです",
    "srt_model_description_small": "通常の速度,精度です",
    "srt_model_description_medium": "速度は遅いが精度は高いです",
    "srt_model_description_large": "非常にゆっくりですが正確です",
    "srt_translate_working": "AIが熱心に翻訳中です。現在のブラウザのウィンドウを閉じると、作業が中断されます",
    "srt_file_type_error": "ファイルタイプは対応していません。",
    "srt_prompt": "プロンプト",
    "srt_prompt_description": "プロンプトはタスクを開始するための指示であり、それは質問、指示、または文であることができます。タスクの正確さは、プロンプトがどれだけ明確に書かれているかによって異なります。"
  },
  "member": {
    "create_time": "创建时间",
    "name": "账号名",
    "member_type": "会员类型",
    "present_months": "赠送月数",
    "validity_period": "有效期",
    "remark": "备注",
    "new_gift": "新增赠送",
    "reset": "重置",
    "search": "查询",
    "member_benefits": "会员权益",
    "present_uid": "赠送UID",
    "close": "关闭",
    "not_free": "以下用户会员等级不是FREE，不支持权益赠送，请修改后重新输入",
    "please_enter": "请输入",
    "please_choose": "请选择",
    "add_new_present": "添加新的权益赠送",
    "split": "可通过”逗号“或\"空格\"分词批量输入",
    "month": "{0}个月",
    "create_success": "新增成功",
    "create_fail": "新增失败",
    "get_list_fail": "获取列表数据失败",
    "reviewer": "操作人"
  },
  "audit": {
    "platform_review": "平台审核",
    "content_review": "内容审核",
    "operation": "操作",
    "op_success": "操作成功",
    "op_fail": "操作失败",
    "confirm_delete": "确认删除此条失败原因吗?",
    "add_child": "新增子项",
    "add_root": "新增一项",
    "template_table": "错误模板表格",
    "save_error": "有内容未填写，无法完成信息保存",
    "audit_edit": "审核配置",
    "qrcode_review": "验证码审核"
  },
  "enterprise": {
    "enterprise_name": "企业名称",
    "business_license": "营业执照",
    "member_number": "成员数量",
    "register_status": "注册状态",
    "operation_status": "运营状态",
    "create_time": "创建时间",
    "register_time": "注册时间",
    "creator": "操作人",
    "new_enterprise": "新增企业",
    "create_new_account": "创建新的企业账户",
    "enterprise_management": "企业管理",
    "operation_status_inactivity": "未激活",
    "operation_status_activity": "已激活",
    "create_success": "创建成功",
    "create_fail": "创建失败",
    "invite_code": "邀请码",
    "operation": "操作",
    "check": "查看",
    "detail_page": "详情页",
    "get_basic_info_fail": "获取企业基本信息失败",
    "get_renew_info_fail": "获取企业续费信息失败",
    "email": "邮箱",
    "paypal": "paypal账户",
    "paypal_order_number": "paypal订单号",
    "basic_information": "基本信息",
    "renew_management": "续费管理",
    "renew_handle": "续费处理",
    "paid_time": "实际付款日期",
    "handle": "处理",
    "renew_status": "付款状态",
    "money": "应付款金额",
    "people": "D-7日成员人数",
    "end_time": "应付款日",
    "countdown_time": "D-7日",
    "unpaid": "未支付",
    "paid": "已支付",
    "handle_success": "处理成功",
    "handle_fail": "处理失败",
    "approve": "批准",
    "approve_time": "批准时间",
    "confirm_approve": "是否确认批准",
    "confirm_approve_success": "批准成功",
    "confirm_approve_fail": "批准失败"
  },
  "feedback": {
    "feedback_type": "フィードバックタイプ",
    "type_all": "すべて",
    "type_login": "登録問題",
    "type_app": "アプリの問題",
    "type_suggest": "アドバイス",
    "type_other": "その他の問題"
  }
};export default jp